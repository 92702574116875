import ApiClient from '../ApiClient';

class WebChannel extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  updateQRCode(inboxId){
    return axios.post(`${this.url}/${inboxId}/update_qrcode`);
  }
}

export default new WebChannel();
