<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import router from '../../../../index';
import { useAlert } from 'dashboard/composables';
import VerificationCode from './VerificationCode.vue';

/* global FB */
export default {
  components: {
    LoadingState,
    VerificationCode,
  },

  data() {
    return {
      hasError: false,
      isCreating: false,
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      errorStateMessage: '',
      errorStateDescription: '',
      hasLoginStarted: false,
      apiKey: '',
      isVerifingCode: false,
      acceptedWhatsappComercialPolice: false,
      acceptedWhatsappBusinessPolice: false,
    };
  },
  computed: {
    hasAcceptedPolicies() {
      return this.acceptedWhatsappComercialPolice && this.acceptedWhatsappBusinessPolice;
    }
  },
  created() {
    this.initFB();
    this.loadFBsdk();
  },

  methods: {
    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          // JavaScript SDK configuration and setup
          FB.init({
            appId: window.vordeskConfig.fbAppId, // Facebook App ID
            version: window.vordeskConfig.fbApiVersion, // Graph API version
            xfbml: true, // parse social plugins on this page
            cookie: true, // enable cookies
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },

    loadFBsdk() {
      ((d, s, id) => {
        var js;
        var fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    startLogin() {
      if(!this.hasAcceptedPolicies) return;

      this.hasLoginStarted = true;
      this.tryFBlogin();
    },

    tryFBlogin() {
      FB.login(
        response => {
          this.hasError = false;
          if (response.status === 'connected') {
            this.apiKey = response.authResponse.code;
            this.isVerifingCode = true;
          } else if (response.status === 'not_authorized') {
            // eslint-disable-next-line no-console
            console.error('FACEBOOK AUTH ERROR', response);
            this.hasError = true;
            this.isVerificationCode = false;
            // The person is logged into Facebook, but not your app.
            this.errorStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED'
            );
            this.errorStateDescription = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED_HELP'
            );
          } else {
            // eslint-disable-next-line no-console
            console.error('FACEBOOK AUTH ERROR', response);
            this.hasError = true;
            this.isVerificationCode = false;
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            this.errorStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
            this.errorStateDescription = '';
          }
        },
        {
          config_id: window.vordeskConfig.fbConfigId,
          response_type: 'code',
          override_default_response_type: true,
        }
      );
    },

    async createChannel(pin) {
      this.isCreating = true;
      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: 'Caixa de Entrada',
            channel: {
              type: 'whatsapp',
              provider: 'whatsapp_signin',
              provider_config: {
                api_key: this.apiKey,
                pin: pin,
              },
            },
          }
        );

        this.isCreating = false;
        this.isVerifingCode = false;

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.isCreating = false;
        this.isVerifingCode = false;
        useAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.join-with-facebook {
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 240px;
  padding: 0 12px;
  font-family: Helvetica, Arial, sans-serif;
}

.join-with-facebook-enabled {
  cursor: pointer;
  background-color: rgb(24, 119, 242);
  color: #fff;
}

.join-with-facebook-disabled {
  background-color: rgb(225, 222, 222);
  color: slategray;
}
</style>

<template>
  <div>
    <VerificationCode
      @go-to-next-step="createChannel"
      v-if="isVerifingCode"
      :is-creating="isCreating"
    />

    <div class="flex flex-col" v-if="!hasLoginStarted">
      <div class="mt-4">
        <div class="flex items-center gap-x-4">
          <div style="flex-basis:55%;">
            <h1 class="text-xl text-slate-800 dark:text-slate-100 mb-6 text-justify">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.TITLE') }}
            </h1>

            <p class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify" style="max-width:90%;">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.DESCRIPTION') }}
            </p>
          </div>

          <div class="mx-8 w-1 h-14 bg-green-500 dark:bg-slate-300 rounded-md"></div>

          <div class="flex flex-col" style="flex-basis:45%;">
            <a
              href="https://faq.whatsapp.com/933578044281252"
              target="_blank"
              class="no-underline visited:text-slate-700 hover:cursor-pointer rounded-lg border-slate-500 border-2 px-4 py-2 flex items-center gap-x-2 self-end mb-4"
            >
              <p class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300	font-semibold visited:text-slate-600 hover:text-green-500">
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.POLICIES.FAQ') }}
              </p>
              <fluent-icon
                :icon="'link'"
                icon-lib="lucide"
                :size="22"
                class="inline cursor-pointer"
              />
            </a>
            <div class="flex items-center gap-2 cursor-pointer">
              <input
                v-model="acceptedWhatsappComercialPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://www.whatsapp.com/legal/commerce-policy/?lang=pt_br"
                target="_blank"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_COMERCIAL_POLICY') }}
              </a>
            </div>
            <div class="flex items-center gap-2 cursor-pointer mb-2">
              <input
                v-model="acceptedWhatsappBusinessPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://business.whatsapp.com/policy"
                target="_blank"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_BUSINESS_POLICY') }}
              </a>
            </div>

            <div>
              <span class="m-0 p-0 font-semibold text-sm text-slate-800 dark:text-slate-100">{{ $t('INBOX_MGMT.ADD.WHATSAPP.ATTENTION_VERIFICATION_CODE') }}</span>
              <span class="p-0 m-0 text-sm text-slate-600 dark:text-slate-300">
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.SAVE_VERIFICATION_CODE') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr class="m-8" />

      <div class="flex flex-col gap-y-10">
        <div style="max-width:55%">
          <h2 class="text-xl text-slate-800 dark:text-slate-100 mb-4 text-justify">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.TITLE') }}
          </h2>

          <p class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify" style="max-width:90%;">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.DESCRIPTION') }}
          </p>
        </div>

        <button
          class="join-with-facebook"
          :class="hasAcceptedPolicies ? 'join-with-facebook-enabled' : 'join-with-facebook-disabled'"
          @click="startLogin"
        >

        {{ $t('INBOX_MGMT.ADD.WHATSAPP.LOGIN_WITH_FACEBOOK') }}
      </button>
      </div>
    </div>
    <div v-else>
      <div v-if="hasError" class="max-w-lg mx-auto text-center">
        <h5>{{ errorStateMessage }}</h5>
        <p
          v-if="errorStateDescription"
          v-dompurify-html="errorStateDescription"
        />
      </div>
    </div>
  </div>
</template>
